import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Box,
  Typography,
} from '@mui/material';
import {PointMachineEventSchema} from 'pages/switch/net/repository/repositoryIndex.ts';
import React, {useEffect, useState} from 'react';
import {fetchPointMachineMeasurements} from 'pages/switch/net/repo.ts';
import {LineChart} from '@mui/x-charts';

export interface SimpleDialogProps {
  open: boolean;
  selectedValue?: PointMachineEventSchema;
  onClose: (value: boolean) => void;
  eventId: string;
  // chartData: Array<PointMachineMeasurementSchema>
}

export default function ChartDialog(props: SimpleDialogProps) {
  const {onClose, eventId, open} = props;
  const [isLoading, setLoadingState] = React.useState(true);
  const [xAxisData, setXAxisData] = useState<Array<number>>([]);
  const [yAxisData, setYAxisData] = useState<Array<number | null>>([]);

  const handleClose = () => {
    onClose(!open);
  };

  useEffect(() => {
    const request = fetchPointMachineMeasurements({
      eventId: eventId,
      page: 1,
      pageSize: 10,
    });
    request
      .then((response) => {
        const measurementData = response.data;

        const xAxisData = measurementData.map((data, index) => {
          return index / 100;
        });
        const yAxisData = measurementData.map((data) => data.current);

        setYAxisData(yAxisData);
        setXAxisData(xAxisData);
        setLoadingState(false);
      })
      .catch((reason) => {
        setLoadingState(false);
      });
  }, []);

  return (
    <Dialog
      style={{backgroundColor: 'transparent'}}
      BackdropProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
      PaperProps={{
        style: {
          // backgroundColor: 'transparent',
          width: '50%',
          boxShadow: 'none',
        },
      }}
      onClose={handleClose}
      open={open}
    >
      <DialogTitle>Event measurement</DialogTitle>
      <Typography
        sx={{
          position: 'relative',
          top: '40px',
          left: '-290px',
          p: 0,
          pr: 5,
          m: 0,
          color: 'black',
          transform: 'rotate(-90deg)',
        }}
      >
        Current [A]
      </Typography>
      <Box
        sx={{
          background:
            'linear-gradient(to right, ' +
            '    #FFFFFF 8.5%,  ' +
            '    #FFFFA8 8.5%,  ' +
            '    #FFFFA8 33%, ' +
            '    #A8FFFF 33%, ' +
            '    #A8FFFF 66%, ' +
            '    #99FFA8 66%, ' +
            '    #99FFA8 92.5%, ' +
            '    #FFFFFF 92.5%, ' +
            '    #FFFFFF 100% ) ',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <LineChart
            width={600}
            height={600}
            series={[{data: yAxisData, showMark: false, color: 'red'}]}
            xAxis={[{data: xAxisData}]}
            grid={{vertical: true, horizontal: true}}
            sx={{
              '& .MuiChartsAxis-root .MuiChartsAxis-line': {
                fill: 'black',
                stroke: 'black',
              },
              stroke: 'black',
            }}
          />
        )}
      </Box>
      <Typography sx={{m: 0, p: 0}} align={'center'}>
        {' '}
        Time [s]{' '}
      </Typography>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
