import React, {useState} from 'react';
import {
  Typography,
  Divider,
  CardContent,
  Card,
  Chip,
  Stack,
} from '@mui/material';
import {Tram, Search} from '@mui/icons-material';
import { TagData, VehicleStatus } from '../../types';

// Example usage
interface SideBarTrainCardProps {
  processedTags: TagData[];
  onSelectTag: (tram: TagData) => void;
  selectedTag:TagData | undefined;
}

const gettingAverageOfSignals = (value: number, count: number): number => {
  const result = (value / count) * 100;
  return parseFloat(result.toFixed(1));
};
const SideBarTrainCard: React.FC<SideBarTrainCardProps> = ({processedTags, onSelectTag, selectedTag}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const filteredData =
    processedTags !== undefined &&
    processedTags.filter((tram: VehicleStatus) =>
      tram.vehicleid.toString().toLowerCase().includes(searchTerm.toLowerCase()),
    );
  return (
    <>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" mb={2}>
          <div style={{position: 'relative', width: '100%'}}>
            <input
              type="text"
              placeholder="Search Tram"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                width: '100%',
                color: 'black',
                padding: '8px 8px 8px 40px',
                borderRadius: '20px',
                backgroundColor: 'white',
                border: '1px solid #3A4750',
                outline: 'none',
              }}
            />
            <Search
              style={{
                position: 'absolute',
                left: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                color: 'gray',
              }}
            />
          </div>
        </Stack>
        <Stack
          spacing={2}
          sx={{
            height: '100vh',
            overflowY: 'auto',
          }}
        >
          {filteredData &&
            filteredData.map((tram:VehicleStatus, index:number) => (
              <Stack
                key={index}
                direction={{xs: 'column', sm: 'row'}}
                spacing={2}
                alignItems="center"

              >
                {/* Icon and Chip */}
                <Stack direction="column" alignItems="center">
                  <Tram sx={{fontSize: 50, color: '#B29739'}} />
                  <Chip
                    label="NU"
                    sx={{
                      backgroundColor: '#B29739',
                      ml: 1,
                      borderRadius: 1,
                      fontWeight: '500',
                    }}
                    size="small"
                  />
                </Stack>

                {/* Tram Card */}
                <Card
                  sx={{
                    width: '100%',
                    backgroundColor: selectedTag == tram ?'#f9f1d1':'#fff',
                    border: '1.96px solid #B29739',
                
                  }}
                  onClick={() => onSelectTag(tram)}
                >
                  <CardContent>
                    <Stack
                      direction={{xs: 'column', sm: 'row'}}
                      justifyContent="space-between"
                      textAlign={{xs: 'center', sm: 'left'}}
                    >
                      <Typography sx={{color: 'black'}}>Spårvagn ID</Typography>
                      <Chip
                        label={tram.vehicleid}
                        sx={{
                          backgroundColor: '#45D175',
                          color: '#3A4750',
                          fontWeight: 'bold',
                          ml: {sm: 2},
                          borderRadius: 1,
                          mt: {xs: 1, sm: 0},
                        }}
                        size="small"
                      />
                    </Stack>
                    <Divider sx={{my: 1, backgroundColor: '#B29739'}} />
                    {/* Tram Details */}
                    <Stack textAlign={{xs: 'center', sm: 'left'}}>
                 
                      <Typography sx={{color: 'black'}}>Starta: {tram.items[0]?.detectionTime}</Typography>
                      {/* <Typography sx={{color: 'black'}}>Slut: {}</Typography> */}
                    </Stack>
                    {/* Signal Averages */}
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} mt={1}>
                      <Chip
                        label={`Warning ${gettingAverageOfSignals(tram.signalAverage.warning, tram.count)}%`}
                        sx={{backgroundColor: '#F17279', color: '#fff', borderRadius: 1}}
                        size="small"
                      />
                      <Chip
                        label={`Alert ${gettingAverageOfSignals(tram.signalAverage.alert, tram.count)}%`}
                        sx={{backgroundColor: '#B29739', color: '#fff', borderRadius: 1}}
                        size="small"
                      />
                      <Chip
                        label={`Good ${gettingAverageOfSignals(tram.signalAverage.good, tram.count)}%`}
                        sx={{backgroundColor: '#45C469', color: '#fff', borderRadius: 1}}
                        size="small"
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Stack>
            ))}
        </Stack>
      </Stack>
      {/* <Grid>
      <Grid alignItems="center" item xs={12} mb={2} sm={12}>
        <div style={{position: 'relative', width: '100%'}}>
          <input
            type="text"
            placeholder="Search Tram"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              width: '100%',
              color: 'black',
              padding: '8px 8px 8px 40px',
              borderRadius: '20px',
              backgroundColor: 'white',
              border: '1px solid #3A4750',
              outline: 'none',
            }}
          />
          <Search
            style={{
              position: 'absolute',
              left: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              color: 'gray',
            }}
          />
        </div>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={1}
        sx={{height: '100vh', overflowY: 'scroll'}}
      >
        {filteredData &&
          filteredData.map((tram: ProcessedData, index: number) => (
            <React.Fragment key={index}>
              <Grid
                item
                xs={12}
                sm={1}
                container
                justifyContent={{xs: 'center', sm: 'flex-start'}}
              
              >
                <Tram sx={{fontSize: 50, color: '#B29739'}} />
                <Chip
                  label="NU"
                  sx={{
                    backgroundColor: '#B29739',
                    ml: 1,
                    borderRadius: 1,
                    fontWeight: '500',
                  }}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <Card
                  sx={{
                    marginBottom: '16px',
                    backgroundColor: '#fff',
                    border: '1.96px solid #B29739',
                  }}
                  onClick={()=>{
                    onSelectTag(tram)
                  }}
                >
                  <CardContent>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      direction={{xs: 'column', sm: 'row'}}
                      textAlign={{xs: 'center', sm: 'left'}}
                    >
                      <Grid item xs={12} sm="auto">
                        <Typography sx={{color: 'black'}}>Spårvagn ID</Typography>
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <Chip
                          label={tram.vehicleid}
                          sx={{
                            backgroundColor: '#45D175',
                            color: '#3A4750',
                            font: 'bold',
                            marginLeft: {sm: '1em'},
                            borderRadius: 1,
                            fontWeight: '500',
                            marginTop: {xs: '0.5em', sm: '0'},
                          }}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <Typography sx={{color: 'black'}}>{}</Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{my: 1, backgroundColor: '#B29739'}} />
                    <Grid container textAlign={{xs: 'center', sm: 'left'}}>
                      <Grid item xs={12}>
                        <Typography sx={{color: 'black'}}>Starta: {}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={{color: 'black'}}>Slut: {}</Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      justifyContent="space-between"
                      direction={{xs: 'column', sm: 'row'}}
                      mt={1}
                      spacing={{xs: 1, sm: 0}}
                    >
                      <Grid item xs={12} sx={{mt: 1}} sm="auto">
                        <Chip
                          label={`Warning ${gettingAverageOfSignals(tram.signalAverage.warning, tram.count)}%`}
                          style={{
                            backgroundColor: '#F17279',
                            borderRadius: 1,
                            color: '#fff',
                          }}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <Chip
                          label={`Alert ${gettingAverageOfSignals(tram.signalAverage.alert, tram.count)}%`}
                          style={{
                            backgroundColor: '#B29739',
                            borderRadius: 1,
                            color: '#fff',
                          }}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <Chip
                          label={`Good ${gettingAverageOfSignals(tram.signalAverage.good, tram.count)}%`}
                          style={{
                            backgroundColor: '#45C469',
                            borderRadius: 1,
                            color: '#fff',
                          }}
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
    </Grid> */}
    </>
  );
};

export default SideBarTrainCard;
