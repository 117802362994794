import React, {useEffect, useRef, useState} from 'react';
import mapboxgl, {Map, LngLatLike} from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {Signal, Feature, DetectorData} from './types/index.ts';
import {throwUnlessDefined} from '../../utils/functional/functionalUtils.ts';
import {AppSettings} from '../../config/appConfigs/appSettings.ts';
import trainMarker from 'assets/images/softprio/trainPointer.png'; // Adjust the path as necessary
import {useGeoJSONLoader} from './hooks/useGeoJSONLoader.ts';
import geoJsonSoftPrio from './tempFile/softPrioTrack.geojson';
import updatedDetectors from './tempFile/updatedDetectors.geojson';
import useFetchTags from './hooks/useFetchTags.ts';
import directionArrow from './tempFile/directionArrow.png';
import bbox from '@turf/bbox';
import tramIcon from './tempFile/tram.png';
function addGlobalStyles() {
  if (!document.getElementById('custom-marker-styles')) {
    const style = document.createElement('style');
    style.id = 'custom-marker-styles';
    style.innerHTML = `
      .marker-pin {
        position:relative;
        width: 5em;
        height: 5em;
        top: -2.5em !important;
        transform: rotate(0deg);
      }
      .marker-pin text {
        font-size: 9em !important;
        dominant-baseline: middle;
        text-anchor: middle;
      }
    `;
    document.head.appendChild(style);
  }
}
function getSignalColor(signalValue: number): string {
  if (signalValue > -65) {
    // above -65
    return '#379C54';
  } else if (signalValue <= -65 && signalValue >= -75) {
    // between -65 and -75
    return '#CBA70E';
  } else if (signalValue < -75 && signalValue >= -85) {
    // between -75 and -85
    return '#fab157';
  } else if (signalValue < -85 && signalValue >= -95) {
    // between -85 and -95
    return '#FA5760';
  } else {
    // below -95
    return ''; // Default case, you can set this to any color or handle differently
  }
}
function stripPrefix(value: string): string {
  const valueStr = value.toString(); // Convert the number to a string
  if (valueStr.startsWith('10')) {
    // Check if it starts with '10'
    return valueStr.slice(2); // Remove the first two characters and convert back to number
  }
  return value; // If it doesn't start with '10', return the original value
}
type TagsPositions = {lng: number; lat: number; id: number}[];
interface MapComponentProps {
  signals: Signal[];
  directionPoints: any[]; // Assuming it's a collection of points
  selectedTag: Feature | null;
  selectedCDC: string;
  functionName: string;
  onAddDetector: (data: DetectorData) => void;
}
interface DirectionPoint {
  type: 'FeatureCollection';
  features: Array<{
    type: 'Feature';
    geometry: {
      type: 'Point';
      coordinates: number[];
    };
    properties: {
      [key: string]: any;
    };
  }>;
}
const {mapboxStyle} = AppSettings;
const MAPBOX_TOKEN = throwUnlessDefined(process.env.REACT_MAPBOX_TOKEN);

const addTrackOutlineLayer = (map: Map, geojsonData: any) => {
  map.addSource('track-outline', {
    type: 'geojson',
    data: geojsonData,
  });

  map.addLayer({
    id: 'track-outline-layer',
    type: 'line',
    source: 'track-outline',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#008000', // Change to desired color
      'line-width': 4,
    },
  });

  const geojsonBounds = bbox(geojsonData);
  map.fitBounds(geojsonBounds as [number, number, number, number]);
};

const addLineLayer = (map: Map) => {
  map.addSource('line-source', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });

  map.addLayer({
    id: 'line-layer',
    type: 'line',
    source: 'line-source',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#888',
      'line-width': 4,
    },
  });
};

const addCustomMarkersLayer = (map: Map) => {
  map.addSource('custom-markers', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });

  map.addLayer({
    id: 'custom-markers',
    type: 'symbol',
    source: 'custom-markers',
    layout: {
      'icon-image': 'custom-marker', // Reference to the image in the sprite
      'icon-size': 1.0, // Adjust the size as needed
      'text-field': ['get', 'title'],
      'text-offset': [0, 1.25],
      'text-anchor': 'top',
    },
    paint: {
      'text-color': '#000000', // Adjust text color if necessary
    },
  });
};
const addLayerForSignals = (map: Map, directionPoints: any) => {
  addGlobalStyles(); // Ensure styles are added only once
  const colors: string[] = ['#FA5760', '#379C54', '#CBA70E'];
  map.addSource('signal-points', {
    type: 'geojson',
    data: directionPoints,
    cluster: true,
    clusterRadius: 50,
    clusterMaxZoom: 14,
  });

  map.addLayer({
    id: 'clusters',
    type: 'circle',
    source: 'signal-points',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': '#51bbd6',
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
    },
  });

  // Check if the cluster-count layer exists before adding it
  if (!map.getLayer('cluster-count')) {
    map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'signal-points',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
      },
    });
  }

  const markers: {[key: string]: mapboxgl.Marker} = {};

  function updateMarkers() {
    const newMarkers: {[key: string]: mapboxgl.Marker} = {};
    const features = map.querySourceFeatures('signal-points', {
      filter: ['!', ['has', 'point_count']],
    });

    features.forEach((feature) => {
      const coords = feature.geometry.coordinates as [number, number];
      const props = feature.properties as any;
      const randomIndex = Math.floor(Math.random() * colors.length);
      const randomColor = colors[randomIndex];
      const id = props.tagID;

      if (!markers[id]) {
        const el = createCustomHTMLMarker(props, randomColor);
        const marker = new mapboxgl.Marker({element: el}).setLngLat(coords).addTo(map);
        markers[id] = marker;
      }
      newMarkers[id] = markers[id];
    });

    for (const id in markers) {
      if (!newMarkers[id]) {
        markers[id].remove();
        delete markers[id];
      }
    }
  }

  function createCustomHTMLMarker(props: any, randomColor: string) {
    const markersContainer = document.createElement('div');
    markersContainer.style.display = 'block';
    const markerPinSvg = `
      <svg class="marker-pin" fill="${randomColor}" height="50px" width="50px" viewBox="0 0 511.974 511.974">
        <g>
          
          <text x="50%" y="35%" text-anchor="middle" font-weight="bolder" fill="${randomColor}">-69</text>
          <path d="M255.987,0C161.882,0,85.321,76.553,85.321,170.641c0,45.901,14.003,73.924,33.391,112.708
            c3.012,6.025,6.17,12.331,9.438,19.038c47.753,98.065,120.055,204.783,120.781,205.85c1.587,2.338,4.233,3.738,7.057,3.738
            s5.47-1.399,7.057-3.738c0.725-1.067,73.028-107.785,120.781-205.85c3.268-6.707,6.426-13.013,9.438-19.038
            c19.388-38.784,33.391-66.807,33.391-112.708C426.654,76.553,350.093,0,255.987,0z M255.987,255.974
            c-47.053,0-85.333-38.281-85.333-85.333s38.281-85.333,85.333-85.333s85.333,38.281,85.333,85.333
            S303.04,255.974,255.987,255.974z"/>
        </g>
        <g>
        <circle cx="50%" cy="32%" r="150" fill="white" />
         <text x="50%" y="35%" text-anchor="middle" font-weight="bolder" fill="${randomColor}">-69</text>
        </g>
      </svg>`;

    markersContainer.innerHTML = markerPinSvg;

    return markersContainer;
  }

  map.on('move', updateMarkers);
  map.on('zoom', updateMarkers);
  map.on('data', updateMarkers);

  updateMarkers();
};

const clusteredLayer = (map: Map, directionPoints: DirectionPoint): void => {
  if (!map.hasImage('direction-icon')) {
    map.loadImage(directionArrow, (error, image) => {
      if (error) {
        console.error('Error loading image:', error);
        return;
      }
      if (!image) {
        console.error('Image is undefined.');
        return;
      }
      map.addImage('direction-icon', image);

      map.addSource('clusteredpoints', {
        type: 'geojson',
        data: directionPoints,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });

      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'clusteredpoints',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            100,
            '#f1f075',
            750,
            '#f28cb1',
          ],
          'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
        },
      });

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'clusteredpoints',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
      });

      map.addLayer({
        id: 'unclustered-point',
        type: 'symbol',
        source: 'clusteredpoints',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': 'direction-icon',
          'icon-size': 0.04, // Adjust the size as needed
          'icon-rotate': ['get', 'direction'], // Rotation value from the feature properties
          'icon-allow-overlap': true,
          'text-anchor': 'top',
        },
      });

      map.on('click', 'unclustered-point', (e) => {
        const features = e.features;
        if (!features || features.length === 0) return;

        const feature = features[0];
        const coordinates = feature.geometry.coordinates.slice();
        const properties = feature.properties;
        console.log('We are gettiong the 0', properties);
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(
            `
          <style>
          .popup-container {
            font-family: Arial, sans-serif;
            padding: 10px;
            border-radius: 8px;
          }
          
          .popup-table {
            width: 100%;
            border-spacing: 0;
            border-collapse: collapse;
          }
          
          .bold {
            font-weight: bold;
          }
          
          .green {
            color: green;
          }
          
          .red {
            color: red;
          }
          
          .align-right {
            text-align: right;
          }
          
          .padding-top {
            padding-top: 8px;
          }
          
          .link {
            color: #0066cc;
          }
          
          .padding-left {
            padding-left: 5px;
          }
          </style>
          <div class="popup-container">
  <table class="popup-table">
    <tr>
      <td class="bold">Detektor</td>
      <td class="align-right bold green">${properties!.detector}</td>
    </tr>
    <tr>
      <td class="padding-top">TagID</td>
      <td class="align-right">${properties!.tagID}</td>
    </tr>
  </table>
</div>
        `,
          )
          .addTo(map);
      });
    });
  }
};

const StatusPage: React.FC<MapComponentProps> = ({
  directionPoints,
  selectedTag,
  selectedCDC,
  functionName,
  onAddDetector,
}) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<Map | null>(null);
  const {geojsonData, loading, error} = useGeoJSONLoader({url: geoJsonSoftPrio});
  const [zoomLevel, setZoomLevel] = useState<number>(0);
  const [mapBounds, setMapBounds] = useState<mapboxgl.LngLatBounds | null>(null);
  const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false);
  const [tagsPosition, setTagsPosition] = useState<TagsPositions>([]);
  const [markerRefs, setMarkerRefs] = useState<Record<number, mapboxgl.Marker>>({});

  const {
    geojsonData: geojsonDataForDetectors,
    loading: loadingDetectors,
    error: errorDetectors,
  } = useGeoJSONLoader({
    url: updatedDetectors,
  });
  const markerRefForTram = useRef<mapboxgl.Marker | null>(null);
  const [isInitialize, setIsInitialized] = useState<boolean>(false);
  const [isPrevTag, setIsPrevTag] = useState<Feature | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [selectedLineIndex, setSelectedLineIndex] = useState<number | null>(null);
  const [selectedPoint, setSelectedPoint] = useState<number[][] | null>(null);
  // For Tags With ID's  //
  const [processedIds, setProcessedIds] = useState<string[]>([]);

  const {
    data: tagsData,
    errors: tagsError,
    isLoading: isTagsLoading,
  } = useFetchTags(processedIds);
  // // Aligned Points //
  // const [alignedPoints, setAlignedPoints] = useState<DirectionPoint[] | null>(null);
  useEffect(() => {
    mapboxgl.accessToken = MAPBOX_TOKEN;
    if (
      mapContainer.current &&
      !map.current &&
      geojsonData &&
      directionPoints &&
      geojsonDataForDetectors
    ) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/light-v11',
        center: [0, 0], // Initial center, will be updated later
        zoom: 15,
      });

      map.current.on('load', () => {
        setIsMapLoaded(true);
        map.current!.on('zoom', () => {
          const zoom = map.current?.getZoom();
          if (zoom !== undefined && zoom >= 15 && zoomLevel < 15) {
            setZoomLevel(zoom);
            const bounds = map.current!.getBounds();
            console.log('Bounds ', bounds);
            setMapBounds(bounds);
          }
        });
        if (geojsonData) {
          addTrackOutlineLayer(map.current!, geojsonData);
          addLineLayer(map.current!);
          addCustomMarkersLayer(map.current!);
          //addDirectionPointsLayer(map.current!, directionPoints);
          // addLayerForSignals(map.current!, geojsonDataForDetectors);
          clusteredLayer(map.current!, geojsonDataForDetectors);
          setIsInitialized(true);
        }
      });
    }

    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, [geojsonData, directionPoints, geojsonDataForDetectors]);
  useEffect(() => {
    addGlobalStyles();
    if (Object.keys(markerRefs).length === 0 || tagsData.length === 0) return;
    tagsPosition.forEach((marker) => {
      tagsData.forEach((data) => {
        const mainData = data.data;
        const dataLength = mainData.length;
        if (dataLength > 0) {
          const targetMarker = markerRefs[mainData[0].eventData.tag_id];

          if (targetMarker) {
            const eventData = mainData[0].eventData;
            const udpData = eventData.udp_packets;
            // Update marker popup
            const popup = new mapboxgl.Popup().setHTML(
              `
              <style>
              .popup-container {
                font-family: Arial, sans-serif;
                padding: 10px;
                border-radius: 8px;
              }
              .popup-table {
                width: 100%;
                border-spacing: 0;
                border-collapse: collapse;
              }
              .bold {
                font-weight: bold;
              }
              .green {
                color: green;
              }
              .red {
                color: red;
              }
              .align-right {
                text-align: right;
              }
              .padding-top {
                padding-top: 8px;
              }
              .link {
                color: #0066cc;
              }
              .progress-bar-bg {
                background-color: lightgray;
                width: 100px;
                height: 10px;
                border-radius: 5px;
                display: inline-block;
                position: relative;
              }
              .progress-bar {
                background-color: ${getSignalColor(udpData.rssi)};
                width: ${udpData.lqi}%;
                height: 100%;
                border-radius: 5px;
              }
              
              .padding-left {
                padding-left: 5px;
              }
              </style>
              <div class="popup-container">
      <table class="popup-table">
        <tr>
          <td class="bold">Spårvagn ID</td>
          <td class="align-right bold green">${stripPrefix(udpData.fordons_id.toString())}</td>
        </tr>
        <tr>
          <td class="padding-top">Detektor</td>
          <td class="align-right">0101/0102</td>
        </tr>
        <tr>
          <td class="padding-top" style="color: ${getSignalColor(udpData.rssi)}">${udpData.rssi} dbm</td>
       
        </tr>
        <tr>
          <td class="padding-top">Ursprung</td>
          <td class="align-right padding-top"><a href="#" class="link">Vagn</a></td>
        </tr>
        <tr>
          <td class="padding-top">Datum</td>
          <td class="align-right padding-top">${udpData.timestamp}</td>
        </tr>
        <tr>
          <td class="padding-top">Apparaturum</td>
          <td class="align-right padding-top">${udpData.destination_address}</td>
        </tr>
        <tr>
          <td class="padding-top">Länkkvalitet</td>
          <td class="align-right padding-top">
            <div class="progress-bar-bg">
              <div class="progress-bar"></div>
            </div>
            <span class="padding-left">${udpData.lqi}%</span>
          </td>
        </tr>
        <tr>
          <td class="padding-top">Buffringsvärde</td>
          <td class="align-right padding-top">${udpData.tag_buffer ? udpData.tag_buffer : 0}</td>
        </tr>
      </table>
    </div>
            `,
            );
            targetMarker.setPopup(popup);
            // Update marker element content
            const markerElement = targetMarker.getElement();
            // Clear the entire marker element properly by removing all children
            markerElement.innerHTML = '';
            while (markerElement.firstChild) {
              markerElement.removeChild(markerElement.firstChild);
            }
            markerElement.style.display = 'block';
            const markerPinSvg = `
              <svg class="marker-pin" fill="${getSignalColor(udpData.rssi)}" height="50px" width="50px" viewBox="0 0 511.974 511.974">
                <g>
                  <text x="50%" y="35%" text-anchor="middle" font-weight="bolder" fill="${getSignalColor(udpData.rssi)}">${udpData.rssi}</text>
                  <path d="M255.987,0C161.882,0,85.321,76.553,85.321,170.641c0,45.901,14.003,73.924,33.391,112.708
                    c3.012,6.025,6.17,12.331,9.438,19.038c47.753,98.065,120.055,204.783,120.781,205.85c1.587,2.338,4.233,3.738,7.057,3.738
                    s5.47-1.399,7.057-3.738c0.725-1.067,73.028-107.785,120.781-205.85c3.268-6.707,6.426-13.013,9.438-19.038
                    c19.388-38.784,33.391-66.807,33.391-112.708C426.654,76.553,350.093,0,255.987,0z M255.987,255.974
                    c-47.053,0-85.333-38.281-85.333-85.333s38.281-85.333,85.333-85.333s85.333,38.281,85.333,85.333
                    S303.04,255.974,255.987,255.974z"/>
                </g>
                <g>
                <circle cx="50%" cy="32%" r="150" fill="white" />
                 <text x="50%" y="35%" text-anchor="middle" font-weight="bolder" fill="${getSignalColor(udpData.rssi)}">${udpData.rssi}</text>
                </g>
              </svg>`;
            markerElement.innerHTML = markerPinSvg;
            // Update the markerRefs with the new marker if needed (reuse existing)
          }
        }
      });
    });
  }, [tagsData, markerRefs, tagsPosition]);
  // This is the Zoom level to call the api //
  useEffect(() => {
    if (!isMapLoaded || !mapBounds || functionName !== 'Radiokvalitet') {
      setMarkerRefs({});
      return;
    }

    // Calculate screen bounds for queryRenderedFeatures
    const southwest = mapBounds.getSouthWest();
    const northeast = mapBounds.getNorthEast();
    const swScreenCoords = map.current?.project([southwest.lng, southwest.lat]);
    const neScreenCoords = map.current?.project([northeast.lng, northeast.lat]);

    if (!swScreenCoords || !neScreenCoords) return;

    const screenBounds = [
      [swScreenCoords.x, swScreenCoords.y], // bottom-left corner
      [neScreenCoords.x, neScreenCoords.y], // top-right corner
    ];

    // Query features within the screen bounds
    const features = map.current!.queryRenderedFeatures(screenBounds, {
      layers: ['unclustered-point'],
    });

    if (!features || features.length === 0) return;
    if (features.length <= 20) {
      const getIds: string[] = [];
      const featurePositions = features
        .map((feature) => {
          const geometry = feature.geometry;
          const id = parseInt(feature.properties!.tagID, 16);
          getIds.push(id.toString());

          if (geometry.type === 'Point') {
            const [lng, lat] = geometry.coordinates;
            return {lng, lat, id};
          }
          return null;
        })
        .filter(Boolean);

      setProcessedIds(getIds);
      setTagsPosition(featurePositions);
      const newMarkerRefs: Record<number, mapboxgl.Marker> = {};
      // Create markers and add them to the map
      featurePositions.forEach((marker) => {
        const targetMarker = markerRefs[marker!.id];
        if (targetMarker) {
          const markerElement = targetMarker.getElement();
          if (markerElement) {
            markerElement.innerHTML = ''; // Update text to 'Loaded'
          }
        } else {
          const markerElement = document.createElement('div');
          markerElement.innerHTML = 'laoding..';
          const mapMarker = new mapboxgl.Marker(markerElement)
            .setLngLat([marker!.lng, marker!.lat])
            .addTo(map.current!);
          newMarkerRefs[marker!.id] = mapMarker;
        }
      });

      setMarkerRefs((prevRefs) => ({...prevRefs, ...newMarkerRefs}));
    }
  }, [zoomLevel, map, mapBounds, isMapLoaded, functionName]);
  useEffect(() => {
    if (isInitialize) {
      if (selectedTag) {
        setIsPrevTag(selectedTag);
        const popup = new mapboxgl.Popup()
          .setLngLat(selectedTag.geometry.coordinates)
          .setHTML(`<h3>Detector:${selectedTag.properties.detector}</h3>`)
          .addTo(map.current!);

        map.current!.flyTo({
          center: selectedTag.geometry.coordinates as LngLatLike,
          zoom: 20,
          essential: true, // Ensures the animation is essential (required for smooth animation)
          speed: 1.5, // Adjust animation speed as needed
          curve: 1.5, // Adjust animation curve as needed
        });
      } else if (isPrevTag) {
        map.current?.flyTo({
          center: isPrevTag.geometry.coordinates as LngLatLike,
          zoom: 12,
          essential: true, // Ensures the animation is essential (required for smooth animation)
          speed: 1.5, // Adjust animation speed as needed
          curve: 1.5, // Adjust animation curve as needed
        });
        setIsPrevTag(null);
      }
    }
  }, [selectedTag, isInitialize]);
  function getRandomIndex(array: [any]) {
    return Math.floor(Math.random() * array.length);
  }
  useEffect(() => {
    if (!map.current || !geojsonData || selectedCDC == '' || selectedCDC === 'NONE')
      return;
    // Select a random line and point if not already selected
    if (selectedLineIndex === null || selectedPoint === null) {
      const randomLineIndex = Math.floor(Math.random() * geojsonData.features.length);
      const randomLine = geojsonData.features[randomLineIndex];
      const coordinates = randomLine.geometry.coordinates;

      const randomIndex = getRandomIndex(coordinates);
      const selectedPoint = coordinates[randomIndex];
      setSelectedLineIndex(randomLineIndex);
      setSelectedPoint(selectedPoint); // This is array of points with lan and lat
      setCurrentIndex(0); // Reset index for the new line
    }
    // Add a marker for each coordinate in the selected line
    const addMarker = (index: number) => {
      // We need to check if there is array of lng, lat array
      if (map.current && selectedPoint && index < selectedPoint.length) {
        const [lng, lat] = selectedPoint[index];
        const el = document.createElement('div');
        el.className = 'custom-marker';
        if (markerRefForTram.current) {
          markerRefForTram.current.remove();
        }
        const newMarker = new mapboxgl.Marker(el)
          .setLngLat([lng, lat])
          .addTo(map.current);
        markerRefForTram.current = newMarker;
        // Update the current index to loop through coordinates
        checkForArrowIntersection([lng, lat]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }
    };

    const interval = setInterval(() => {
      if (
        selectedPoint &&
        currentIndex <
          geojsonData.features[selectedLineIndex!]?.geometry.coordinates.length
      ) {
        addMarker(currentIndex);
      } else {
        // Finished current line, reset to select a new random line
        setSelectedLineIndex(null);
        setSelectedPoint(null);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [geojsonData, map, currentIndex, selectedLineIndex, selectedPoint, selectedCDC]);

  useEffect(() => {
    return () => {
      if (map.current && map.current.hasImage('custom-marker')) {
        map.current.removeImage('custom-marker');
      }
    };
  }, []);
  // This is for the Blinking Effect //
  useEffect(() => {
    const blinkInterval = setInterval(() => {
      const el = document.querySelector('.custom-marker');
      if (el) {
        el.classList.toggle('blink');
      }
    }, 1000);
    return () => clearInterval(blinkInterval);
  }, []);

  // This is to enable the Functions //
  useEffect(() => {
    if (map.current && functionName === 'Detektorer') {
      // Add the layer and source when the condition is met
      // addLayerForSignals(map.current, geojsonDataForDetectors);  //
      map.current.setLayoutProperty('clusters', 'visibility', 'visible');
      map.current.setLayoutProperty('cluster-count', 'visibility', 'visible');
    } else {
      if (map.current?.getLayer('clusters')) {
        map.current.setLayoutProperty('clusters', 'visibility', 'none');
      }
      if (map.current?.getLayer('cluster-count')) {
        map.current.setLayoutProperty('cluster-count', 'visibility', 'none');
      }
    }
    // Cleanup function to remove the layer and source when dependencies change or component unmounts
    return () => {
    };
  }, [functionName, map, geojsonDataForDetectors]);

  const checkForArrowIntersection = (point: [number, number]) => {
    directionPoints.features.forEach((feature: any) => {
      const arrowCoords = feature.geometry.coordinates;
      const distance = mapboxgl.LngLat.convert(point).distanceTo(
        mapboxgl.LngLat.convert(arrowCoords),
      );
      if (distance < 10) {
        onAddDetector(feature.properties);
      }
    });
  };

  return (
    <div
      ref={mapContainer}
      id="map"
      className="map-container"
      style={{height: '100vh', width: '100vw'}}
    >
      <style>
        {`
          .custom-marker {
            width: 32px;
            height: 32px;
            background-image: url(${tramIcon});
            background-size: cover;
            border-radius: 50%;
            transform: translate(-50%, -50%);
          }
          .blink {
            animation: blink-animation 1s infinite;
          }
          @keyframes blink-animation {
            0%, 50%, 100% { opacity: 1; }
            25%, 75% { opacity: 0; }
          }
          .direction-marker {
            width: 32px;
            height: 32px;
            background-image: url(${trainMarker});
            background-size: cover;
            border-radius: 50%;
            transform: translate(-50%, -50%);
          }
        `}
      </style>
    </div>
  );
};

export default StatusPage;
