import {Perhaps} from 'types/typeHelpers/perhaps';
import React from 'react';
import {AlertScopeProps} from 'types/alerts/alertScopeProps';
import {FrontendView} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {isViewActive} from 'appUtils/cemitAppUtils/viewUtils.ts';
import {Stack} from '@mui/material';
import AlertTypesOfErrorsComponent, {
  AlertTypesOfErrorsComponentProps,
} from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/AlertTypesOfErrorsComponent.tsx';
import {chain, difference, keys, length, map, omit} from 'ramda';
import {ts} from 'appUtils/typeUtils/clsOrType.ts';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {AlertGaugeByTimePeriod} from 'types/alerts/alertGauge';
import AlertOptionsMenu from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/AlertOptionsMenu.tsx';
import {lengthAsBoolean} from 'utils/functional/functionalUtils.ts';
import {alertIncomingOrExistingStatusIsIncomplete} from 'appUtils/alertUtils/alertScopePropsUtils.ts';
import {TrainAppMapComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapComponentDependencyProps.ts';
import {resolveActiveTrainGroupCrudList} from 'appUtils/trainAppUtils/scope/trainGroupCrudScope.ts';

interface AlertChartContainerProps extends CemitComponentProps {}

/**
 * RideComfort statistics for each given componentProps.activeTrainGroups
 * @param appProps
 * @param organizationProps
 * @param trainProps
 */
const AlertChartContainer = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
}: Omit<
  TrainAppMapComponentDependencyProps<AlertChartContainerProps>,
  'organizationProps'
>): Perhaps<JSX.Element> => {
  const alertScopePropSets = trainProps.alertProps.alertScopePropSets;

  const loading = !length(alertScopePropSets);
  // Extract the activeTrainGroups from alertScopePropSets
  const activeTrainGroups = useNotLoadingMemo(
    loading,
    (alertScopePropSets) => {
      return map((alertScopeProps: AlertScopeProps) => {
        return alertScopeProps.scopedTrainGroup;
      }, alertScopePropSets);
    },
    [alertScopePropSets],
  );

  const dependencies = [
    activeTrainGroups,
    trainProps.alertConfigProps,
    trainProps.alertProps.alertScopePropSets,
  ] as const;
  const alertTypesOfErrorComponents = useNotLoadingMemo(
    !activeTrainGroups,
    (activeTrainGroups) =>
      // Chain RideComfortTypesOfErrorsComponents, since each activeTrainGroup results in a list of them
      chain((alertScopeProps: AlertScopeProps): React.JSX.Element => {
        const activeTrainGroup = alertScopeProps.scopedTrainGroup;

        // If we are displaying one TrainGroup, show all gauges for it side-by-side
        // Else if we are displaying multiple TrainGroups, show one gauge per TrainGroup and
        // allow the user to select the desired alertGaugeByTimePeriods (today, week, or month)
        const alertGaugeByTimePeriods = keys(
          omit(
            ['__typename'],
            alertScopeProps.scopedTrainGroup.alertScopeProps?.alertTrainGroupProps
              ?.alertGaugeByTimePeriod,
          ),
        );
        const alertGaugeByTimePeriodsToShow: (keyof AlertGaugeByTimePeriod)[] = (
          length(activeTrainGroups) > 1
            ? // If multiple activeTrainGroups, only show the selected alertTimePeriodForMap
              [alertScopeProps.alertConfigProps.alertGaugeTimePeriod]
            : // If 1 activeTrainGroup, show all
              alertGaugeByTimePeriods
        ) as (keyof AlertGaugeByTimePeriod)[];
        // These non-showing alertGaugeByTimePeriods can be shown as inner rings in the pie chart
        const otherRideComfortGaugeByTimePeriods: (keyof AlertGaugeByTimePeriod)[] =
          difference(
            alertGaugeByTimePeriods,
            alertGaugeByTimePeriodsToShow,
          ) as (keyof AlertGaugeByTimePeriod)[];

        // This map is here to show all the alertTimePeriodForMap levels at once
        return map((alertTimePeriodForMap: keyof AlertGaugeByTimePeriod) => {
          return (
            <AlertTypesOfErrorsComponent
              key={`${activeTrainGroup.id}${alertTimePeriodForMap}`}
              {...{
                ...ts<AlertTypesOfErrorsComponentProps>({
                  // If in an overall loading status, or the loading of this TrainGroup's RideComfort hasn't been triggered yet,
                  // then we are in a loading state
                  loading:
                    loading || alertIncomingOrExistingStatusIsIncomplete(alertScopeProps),
                  trainGroup: activeTrainGroup,
                  trainGroupCrudList: resolveActiveTrainGroupCrudList(trainProps),
                  alertScopeProps,
                  showAlertTimePeriod: alertTimePeriodForMap,
                  otherAlertGaugeByTimePeriods: otherRideComfortGaugeByTimePeriods,
                  sx: {
                    textWrap: 'nowrap',
                  },
                }),
              }}
            />
          );
        }, alertGaugeByTimePeriodsToShow);
      }, alertScopePropSets),
    dependencies,
  );

  // TODO this should be FrontendView.detailTrainFormationAlert or FrontendView.detailTrainGroupAlert
  return isViewActive(appProps, FrontendView.detailTrainFormation) ? (
    <Stack {...{direction: 'row', spacing: 1, sx: {minHeight: '300px'}}}>
      {alertTypesOfErrorComponents}
      {lengthAsBoolean(alertTypesOfErrorComponents) ? (
        <AlertOptionsMenu
          key="alertOptionsMenu"
          {...{
            appProps,
            organizationProps,
            trainProps,
            mapProps,
          }}
        />
      ) : undefined}
    </Stack>
  ) : undefined;
};
AlertChartContainer.displayName = 'AlertChartContainer';
export default AlertChartContainer;
