import {Layer, Source} from 'react-map-gl';
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {useTheme} from '@mui/styles';
import {MapDialog} from 'pages/trafficsim/trafficSimComponents/dialog/mapDialog.tsx';
import CustomPopup from 'pages/trafficsim/trafficSimComponents/themedComponents/CustomPopup.tsx';
import {MapComponentActionType} from 'pages/trafficsim/trafficSimComponents/track/v2/TrafficSimMap.tsx';
import {ACCESS_LEVELS} from '../../../utils/access_levels.ts';
import {AuthContext} from 'pages/trafficsim/context/auth/AuthProviderTODORemove.tsx';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {UserContext, UserContextType} from 'pages/auth/UserContext.ts';

export function Section({mapRef, section, feedPointArr, stationsArr, onUpdateSection}) {
  const theme = useTheme();

  const [{showPopUp, lngLat}, setPopupState] = useState({
    showPopUp: false,
    lngLat: undefined,
  });
  const userState = useContext<Perhaps<UserContextType>>(UserContext)!
    .userState as UserStateLoaded;

  const regularFeedPoints = feedPointArr.filter((fp) => !fp.isSpare);
  const regularFeedPointsCount = regularFeedPoints.length;
  const activeFeedPointStationsIds = feedPointArr
    .filter((fp: any) => fp.isActive)
    .map((fp: any) => fp.stations);
  const activeStations = activeFeedPointStationsIds.flatMap((stationId: string) =>
    stationsArr.filter((station: any) => station.internal_id === stationId),
  );
  const isSectionOpen = section.isOpen;
  const isInSafeTrackMode = section.isInSafeTrackMode;
  const sectionId = section.internal_id;
  const openFeedPoints = feedPointArr.filter((fp) => fp.isActive === true && fp.isOpen);
  const areSomeFeedpointsClosed = openFeedPoints.length < regularFeedPointsCount;
  const isAnyStationClosed = activeStations.some((s) => s.isOpen === false);
  const areAllStationsClosed = activeStations.every((s) => s.isOpen === false);
  const areAllFeedpointsClosed = feedPointArr.every((fp) => {
    const station = stationsArr.filter(
      (station: any) => station.internal_id === fp.stations,
    )[0];
    if (station !== undefined && !station.isOpen) return true;
    return fp.isSpare ? fp.isActive === true && fp.isOpen == false : fp.isOpen === false;
  });
  const sectionLabel = `Spårsektion: ${section.Namn}`;
  const layerId = `section-layer-${sectionId}`;
  const isSingleFeedPointOnSection =
    feedPointArr.filter((fp: any) => fp.isActive === true).length === 1;
  const isSectionYellowOrGreen =
    areSomeFeedpointsClosed || isAnyStationClosed
      ? theme.palette.map.gold
      : theme.palette.map.green;
  const isSectionClosed =
    areAllFeedpointsClosed || areAllStationsClosed || isInSafeTrackMode || !isSectionOpen;
  const trackColor = isSectionClosed ? theme.palette.map.red : isSectionYellowOrGreen;

  let prepareGeoJson = (id, section, trackColor) => {
    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            id: id,
            color: trackColor,
          },
          geometry: {
            type: 'MultiLineString',
            coordinates: section.coordinates,
          },
        },
      ],
    };
  };
  const geoJSON = prepareGeoJson(sectionId, section, trackColor);

  useEffect(() => {
    if (!mapRef) {
      return undefined;
    }
    const onClickLayer = (e) => {
      e.originalEvent.stopPropagation();

      if (e.features.length > 0) {
        const clickedId = e.features[0].properties.id;
        console.log(clickedId);
        const {lng, lat} = e.lngLat;

        const mapZoomLevel = mapRef.current.getZoom();
        const isMapZoomedIn = mapZoomLevel >= 16;
        if (!isMapZoomedIn) {
          mapRef.current.flyTo({
            center: [lng, lat],
            zoom: 16,
            essential: true,
          });
          return;
        }
        mapRef.current.flyTo({
          center: [lng, lat],
          zoom: mapZoomLevel,
          essential: true,
        });

        setPopupState({showPopUp: true, lngLat: e.lngLat});
      }
    };

    if (
      userState.access.trafficSimAccessLevel &&
      userState.access.trafficSimAccessLevel !== ACCESS_LEVELS.READ_ONLY
    ) {
      mapRef.current.on('click', layerId, onClickLayer);
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.off('click', layerId, onClickLayer);
      }
    };
  }, [isSectionOpen]);

  const onClose = () => {
    setPopupState({showPopUp: false, lngLat: undefined});
  };

  const onSave = (notes, voltageStatus) => {
    if (voltageStatus === isSectionOpen) {
      onClose();
      return;
    }

    const actionType = voltageStatus
      ? MapComponentActionType.OPEN
      : MapComponentActionType.CLOSE;
    const updatedSection = {
      ...section,
      comments: notes,
    };
    onUpdateSection(updatedSection, stationsArr, actionType);
    onClose();
  };

  const lineStatus = !isSectionOpen ? isSectionOpen : isInSafeTrackMode;

  return (
    <>
      <Source id={sectionId} type="geojson" data={geoJSON}>
        <Layer
          id={layerId}
          type="line"
          source={sectionId}
          cursor="pointer"
          interactive={true}
          paint={{
            'line-width': lineStatus ? 1.75 : 3.5,
            'line-color': ['get', 'color'],
            'line-dasharray': lineStatus ? [0.02, 0.02] : [0.5],
          }}
        />
      </Source>

      {showPopUp && lngLat && (
        <CustomPopup
          longitude={lngLat.lng}
          latitude={lngLat.lat}
          anchor="bottom"
          onClose={() => onClose()}
        >
          <MapDialog
            type="section"
            label={sectionLabel}
            sectionStatus={isSectionOpen}
            data={section}
            onSave={onSave}
            onCancel={onClose}
          />
        </CustomPopup>
      )}
    </>
  );
}
