import { useQuery } from '@tanstack/react-query';
import { TagData } from '../types';

const fetchDataStream = async (pageSize: number = 50): Promise<TagData[]> => {
  const response = await fetch(
    `https://api.core-dev.cemit.digital/api/v1/detector/detector_events?repage=1&pageSize=${pageSize}`,
    {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    },
  );
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const useFetchAllTags = (pageSize: number = 50) => {
  const { isPending, error, data} = useQuery<TagData[]>({
    queryKey: ['fetchAllTags', pageSize],
    queryFn: () => fetchDataStream(pageSize),
    staleTime: 5 * 60 * 1000, // 5 minutes
    // gcTime: 10 * 60 * 1000, // Cache time of 10 minutes
  });

  return {
    data,
    isPending,
    error,
  };
};

export default useFetchAllTags;