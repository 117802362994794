import {useEffect, useState, useRef} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {TagData} from '../types';

// Debounce hook to prevent rapid successive API calls
function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    // Cleanup the timeout on value change or unmount
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}

// Function to fetch tag data from the API
const fetchDataStream = async (referenceId: string): Promise<TagData> => {
  const response = await fetch(
    `https://api.core-dev.cemit.digital/api/v1/detector/detector_events?reference_id=${referenceId}&page=1&pageSize=1`,
    {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    },
  );
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

// Custom hook to fetch tag data lazily, one at a time
const useFetchTags = (datastreamIds: string[]) => {
  const queryClient = useQueryClient(); // React-query cache
  const [fetchedData, setFetchedData] = useState<TagData[]>([]); // Store fetched data incrementally
  const [loadingIds, setLoadingIds] = useState<string[]>([]); // IDs currently being loaded
  const [errors, setErrors] = useState<string[]>([]); // Store errors for individual requests

  // Use debounced value for datastreamIds to avoid making requests too frequently
  const debouncedDatastreamIds = useDebounce(datastreamIds, 0); // Debounce by 0ms

  const prevDatastreamIds = useRef<string[]>([]); // Store the previous IDs for comparison

  // Utility function to check if two arrays of IDs are the same
  //   const arraysAreEqual = (arr1: string[], arr2: string[]) => {
  //     if (arr1.length !== arr2.length) return false;
  //     const sortedArr1 = [...arr1].sort();
  //     const sortedArr2 = [...arr2].sort();
  //     return sortedArr1.every((id, index) => id === sortedArr2[index]);
  //   };
  const isInCache = (id: string) => {
    return queryClient.getQueryData<TagData>(['fetchTags', id]);
  };
  // Function to fetch data lazily for each ID
  const fetchTagsLazily = async (ids: string[]) => {
    setFetchedData((prev) => {
      const newData = prev.slice(); // Copy previous data
      const idsToFetch = ids.filter((id) => !isInCache(id));

      // Fetch only the IDs that are not in the cache
      idsToFetch.forEach(async (id) => {
        setLoadingIds((prev) => [...prev, id]); // Mark this ID as loading

        try {
          const data = await fetchDataStream(id);
          setFetchedData((prevData) => [...prevData, data]);
          queryClient.setQueryData(['fetchTags', id], data); // Cache the result
        } catch (error) {
          setErrors((prevErrors) => [
            ...prevErrors,
            `Error fetching data for ID ${id}: ${error}`,
          ]);
        } finally {
          setLoadingIds((prev) => prev.filter((loadingId) => loadingId !== id)); // Remove ID from loading list
        }
      });

      return newData;
    });
  };

  // Trigger fetching lazily when datastreamIds change and are different from the previous ones
  useEffect(() => {
    fetchTagsLazily(debouncedDatastreamIds); // Start fetching lazily
    prevDatastreamIds.current = debouncedDatastreamIds; // Update the previous IDs
  }, [debouncedDatastreamIds]);

  return {
    data: fetchedData,
    isLoading: loadingIds.length > 0, // If there are any IDs still loading
    loadingIds, // The IDs currently being loaded
    errors, // Any errors that occurred during fetching
  };
};

export default useFetchTags;
