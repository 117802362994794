import {Box, Stack} from '@mui/material';
import React from 'react';
import {Perhaps} from 'types/typeHelpers/perhaps';
import TrainPageSliderButton from './TrainPageSliderButton.tsx';
import {FrontendView} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {getOrganizationViews} from 'utils/organization/organizationUtils.ts';
import {equals, includes} from 'ramda';
import {HideSource, Public, VerticalSplit} from '@mui/icons-material';
import {ViewSlider} from 'types/propTypes/viewSlider.ts';
import {CEMIT_DARK_RED, CEMIT_WHITE} from 'theme/cemitColors.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {CemitAppProfileItem} from 'components/apps/cemitAppComponents/CemitAppProfileItem.tsx';
import LoaderWithText from '../../../components/loading/LoaderWithText.tsx';
import {AlertChoosersComponent} from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/AlertChoosersComponent.tsx';

const TrainFilterComponent = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
}: TrainAppMapDependencyProps) => {
  // The slider isn't need if the map isn't included
  // If the organization has a map or detail page, include the expander/contractor arrow
  const views: FrontendView[] = getOrganizationViews(organizationProps.organization)!;
  if (!views) {
    return undefined;
  }
  const needsTrainMap: boolean = includes(FrontendView.map, views);

  const sliderIsNormal = equals(ViewSlider.normal, appProps.viewSlider);
  const leftMessage = appProps.t(sliderIsNormal ? 'expandMap' : 'showMap');
  const rightMessage = appProps.t(sliderIsNormal ? 'hideMap' : 'shrinkMap');
  const trainPageSliderButton: Perhaps<JSX.Element> = needsTrainMap ? (
    <TrainPageSliderButton
      {...{
        appProps,
        mapProps,
        componentProps: {
          left: {
            iconComponent: (
              <Public
                {...{
                  fontSize: sliderIsNormal ? 'large' : 'medium',
                  sx: {color: CEMIT_WHITE},
                }}
              />
            ),
            tooltipMessage: leftMessage,
          },
          right: {
            iconComponent: (
              <Box {...{position: 'relative', display: 'contents'}}>
                {sliderIsNormal ? (
                  [
                    <Public
                      key="mapIcon"
                      {...{
                        fontSize: 'small',
                        sx: {
                          position: 'absolute',
                          left: '50%',
                          top: '50%',
                          transform: 'translate(-50%,-50%)',
                          zIndex: 1,
                        },
                      }}
                    />,
                    <HideSource
                      key="hideIcon"
                      {...{
                        fontSize: 'large',
                        sx: {color: CEMIT_DARK_RED, zIndex: 2},
                      }}
                    />,
                  ]
                ) : (
                  <VerticalSplit {...{fontSize: 'medium', sx: {color: CEMIT_WHITE}}} />
                )}
              </Box>
            ),
            tooltipMessage: rightMessage,
          },
        },
      }}
    ></TrainPageSliderButton>
  ) : undefined;

  return (
    <Stack
      key="sliderAndFilterAndProfile"
      {...{
        direction: 'row',
        sx: {
          paddingRight: '10px',
          minHeight: 0,
          flex: 1,
          overflow: 'hidden',
          maxHeight: '70px',
          // Force the TrainBoard to be full width
          minWidth: '100%',
          width: '100%',
          height: '100%',
          zIndex: 2,
          justifyContent: 'space-between',
        },
      }}
    >
      {trainProps.trainFormationDateProps.loading ? (
        // Make the Component take up space with an empty LoaderWithText
        <LoaderWithText {...{text: ''}} show={} />
      ) : (
        <AlertChoosersComponent
          key="rideComfortChoosersComponent"
          {...{
            appProps,
            organizationProps,
            trainProps,
          }}
        />
      )}
      <Stack
        key="SliderAndProfile"
        {...{
          direction: 'row',
          spacing: 1,
          sx: {
            minWidth: 0,
            minHeight: 0,
            overflow: 'hidden',
          },
        }}
      >
        {trainPageSliderButton}
        {organizationProps?.organization?.logo &&
          <Box {...{component: 'img', sx: {paddingTop: '10px', maxHeight: '50px'}, src:organizationProps.organization.logo}} />

        }
        <CemitAppProfileItem
          {...{
            appProps,
            organizationProps,
            trainProps,
          }}
        />
      </Stack>
    </Stack>
  );
};
export default TrainFilterComponent;
