import {Stack, Typography} from '@mui/material';
import AlertGaugeComponent from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/AlertGaugeComponent.tsx';
import React, {useRef} from 'react';
import {cemitColors} from 'theme/cemitColors.ts';
import {falsyToUndefined, toArrayIfNot} from 'utils/functional/functionalUtils.ts';
import {AlertScopeProps} from 'types/alerts/alertScopeProps';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {isNil, join, length, map, toLower, unless} from 'ramda';
import {useTranslation} from 'react-i18next';
import {AlertViewOption} from 'types/alerts/alertViewOption';
import {AlertBaseGauge, AlertGaugeByTimePeriod} from 'types/alerts/alertGauge';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import AlertErrorLevelsComponent from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/AlertErrorLevelsComponent.tsx';
import {resolveActiveTrainGroupCrudList} from 'appUtils/trainAppUtils/scope/trainGroupCrudScope.ts';

export interface AlertTypesOfErrorsComponentProps extends CemitComponentProps {
  loading?: boolean;
  trainGroup: TrainGroup;
  trainGroupCrudList: CrudList<TrainGroup>;
  alertScopeProps: AlertScopeProps;
  // The dropdown options if the user can change the AlertViewOption here
  alertViewOptions?: AlertViewOption[];
  // The value to show in this component, not that which is currently active on the map
  showAlertTimePeriod: keyof Omit<AlertGaugeByTimePeriod, '__typename'>;
  otherAlertGaugeByTimePeriods: (keyof AlertGaugeByTimePeriod)[];
  showCount?: boolean;
  showDonut?: boolean;
  // The layout direction of the alerts
  direction?: 'column' | 'row';
  // Whether to show the alerts title with the chosen showAlertTimePeriodForMap
  showTitle?: boolean;
  // Whether to show the title of each alert or just the icon
  showAlertTitles?: boolean;
  // Whether or not to show a border
  showBorder?: boolean;
}

/**
 * Shows warnings for one of the attributes of alertTrainGroupProps.alertGaugeByTimePeriod,
 * e.g. month, week, day as well as a menu to select the time period
 * @param alertScopeProps
 * @param scopedAlertGaugeByTimePeriod
 * @param sx
 * @param showCount
 * @param showDonut
 * @constructor
 */
const AlertTypesOfErrorsComponent = ({
  loading = false,
  sx,
  trainGroup,
  trainGroupCrudList,
  alertScopeProps,
  showAlertTimePeriod,
  otherAlertGaugeByTimePeriods,
  showCount = true,
  showDonut = true,
  direction = 'column',
  showTitle = true,
  showAlertTitles = true,
  showBorder = true,
}: AlertTypesOfErrorsComponentProps): JSX.Element => {
  const {t} = useTranslation();

  const alertTypeLabel: string = alertScopeProps.alertConfigProps.alertTypeConfig.label;
  // Get the AlertGauge data matching alertScopeProps.alertConfigPropt.alertTimePeriodForMap,
  // which is the alertTimePeriodForMap selected in the menu by the user below
  const alertGauge: Perhaps<AlertBaseGauge> = useNotLoadingMemo(
    loading,
    (alertTrainGroupProps) => {
      return alertTrainGroupProps.alertGaugeByTimePeriod[
        showAlertTimePeriod
      ] as AlertBaseGauge;
    },
    [
      alertScopeProps.alertTrainGroupProps,
      alertScopeProps.alertConfigProps.alertTypeConfig,
    ],
  );

  const otherAlertGauges: Perhaps<AlertBaseGauge[]> = useNotLoadingMemo(
    loading,
    (alertTrainGroupProps) => {
      return map((otherAlertGaugeByTimePeriod) => {
        return alertTrainGroupProps.alertGaugeByTimePeriod[
          otherAlertGaugeByTimePeriod
        ] as AlertBaseGauge;
      }, otherAlertGaugeByTimePeriods);
    },
    [alertScopeProps.alertTrainGroupProps],
  );

  // Show either just showAlertTimePeriod or showAlertTimePeriod with
  // otherAlertGaugeByTimePeriod in parens
  const alertTimePeriodLabel = t(showAlertTimePeriod);
  const otherAlertTimePeriodLabels = length(otherAlertGaugeByTimePeriods)
    ? `(${join(
        ', ',
        map((otherAlertGaugeByTimePeriod) => {
          return toLower(t(otherAlertGaugeByTimePeriod));
        }, otherAlertGaugeByTimePeriods),
      )})`
    : undefined;
  const alertTimePeriodLabels = (
    <Stack
      {...{
        direction: 'row',
        spacing: 0.5,
        justifyContent: 'flex-start',
        alignItems: 'center',
        // Used to match the alert type subtitle
        paddingLeft: '4px',
      }}
    >
      <Typography {...{fontSize: '12px', color: 'secondary'}}>
        {
          // The enum key alertScopeProps.alertConfigProps.alertTimePeriodForMap
          // is used to form the alertTimePeriodLabels
          alertTimePeriodLabel
        }
      </Typography>
      {otherAlertTimePeriodLabels && (
        <Typography {...{fontSize: '11px', color: 'secondary'}}>
          {otherAlertTimePeriodLabels}
        </Typography>
      )}
    </Stack>
  );

  const title = showTitle ? (
    <Stack
      key="label"
      {...{
        direction: 'column',
        spacing: 0,
        sx: {
          alignItems: 'start',
          justifyContent: 'flex-start',
        },
      }}
    >
      <Typography
        key="typesOfErrors"
        {...{
          variant: 'subtitle1',
          sx: {
            color: cemitColors.white,
            paddingY: '4px',
            paddingLeft: '4px',
          },
        }}
      >
        {alertTypeLabel}
      </Typography>
      {alertTimePeriodLabels}
    </Stack>
  ) : undefined;

  return (
    <Stack
      {...{
        sx: [
          {
            minWidth: 0,
            minHeight: 0,
            flex: 1,
            overflow: 'hidden',
            position: 'relative',
            padding: showTitle ? '4px' : '1px',
            backgroundColor: showBorder ? cemitColors.darkGrey : 'inherit',
            ...(showBorder
              ? {
                  border: `1.64px solid ${cemitColors.white}`,
                  borderRadius: '8px',
                }
              : {}),
          },
          ...toArrayIfNot(sx),
        ],
      }}
    >
      {title}
      <Stack
        key="alertGauge"
        {...{
          direction: 'column',
          spacing: 1,
          sx: {
            width: '100%',
            height: '100%',
            alignItems: 'center',
            minWidth: 0,
            minHeight: 0,
            flex: 1,
            overflow: 'hidden',
            position: 'relative',
          },
        }}
      >
        {unless(isNil, () => (
          <AlertGaugeComponent
            {...{
              loading,
              alertTypeConfig: alertScopeProps.alertConfigProps.alertTypeConfig,
              alertGauge,
              otherAlertGauges,
            }}
          />
        ))(falsyToUndefined(showDonut))}

        <AlertErrorLevelsComponent
          {...{
            loading,
            trainGroup,
            alertGauge,
            showCount,
            direction,
            showAlertTitles,
            alertErrorLevelConfigs:
              alertGauge?.alertTypeConfig?.attributeAlertLevelConfigs,
            trainGroupCrudList,
          }}
        />
      </Stack>
    </Stack>
  );
};

export default AlertTypesOfErrorsComponent;
