import {AppHttpClient} from './api/api.ts';
import {
  IPointMachineEventRequest,
  IPointMachineMeasurementRequest,
  ITurnoutRequest,
} from './interfaces/request/requests.ts';

const turnoutClients = AppHttpClient.turnouts;

export function fetchSwitches({page = 1, pageSize = 10}: ITurnoutRequest) {
  return turnoutClients.listTurnoutsTurnoutsGet(page, pageSize);
}

export function fetchPointMachineEvents({
  machineId,
  pageSize = 10,
  page = 1,
  startDate = null,
  endDate = null,
}: IPointMachineEventRequest) {
  return turnoutClients.listPointMachineEventsTurnoutsPointMachineEventsGet(
    machineId,
    startDate,
    endDate,
    page,
    pageSize,
  );
}

export async function fetchPointMachineMeasurements({
  eventId,
  page = 1,
  pageSize = 1000,
}: IPointMachineMeasurementRequest) {
  return turnoutClients.listPointMachineMeasurementsTurnoutsPointMachineEventsEventIdMeasurementGet(
    eventId,
  );
}
