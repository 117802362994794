/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {BaseHttpRequest} from './core/BaseHttpRequest.ts';
import type {OpenAPIConfig} from './core/OpenAPI.ts';
import {FetchHttpRequest} from './core/FetchHttpRequest.ts';
import {AuthenticationService} from './services/AuthenticationService.ts';
import {CachesService} from './services/CachesService.ts';
import {DevicesService} from './services/DevicesService.ts';
import {DocumentationService} from './services/DocumentationService.ts';
import {TrainsService} from './services/TrainsService.ts';
import {TurnoutsService} from './services/TurnoutsService.ts';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class HttpClient {
  public readonly authentication: AuthenticationService;
  public readonly caches: CachesService;
  public readonly devices: DevicesService;
  public readonly documentation: DocumentationService;
  public readonly trains: TrainsService;
  public readonly turnouts: TurnoutsService;
  public readonly request: BaseHttpRequest;
  constructor(
    config?: Partial<OpenAPIConfig>,
    HttpRequest: HttpRequestConstructor = FetchHttpRequest,
  ) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '/api/v1',
      VERSION: config?.VERSION ?? 'local',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });
    this.authentication = new AuthenticationService(this.request);
    this.caches = new CachesService(this.request);
    this.devices = new DevicesService(this.request);
    this.documentation = new DocumentationService(this.request);
    this.trains = new TrainsService(this.request);
    this.turnouts = new TurnoutsService(this.request);
  }
}
