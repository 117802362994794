/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Enum which defines all measurement types on a point machine
 */
export enum PointMachineMeasurementTypeEnum {
    CURRENT = 'current',
    POWER = 'power',
}
